<template>
	<div class="maxBox" :class="!isMobile ? 'pcbox' : ''">
		<h1>회사 소개</h1>
		<div class="textDes">
			<p>장기적인 발전에 매우 중시하고 있으며 가장 우수한 게임을 연구하고 유저분들에게 가장 좋은 게임 체험을 드리겠습니다.</p>
			<p>빠르고 유저분들에게 만족스러운 답변과 소통을 중요시하며 유저분들과 함께 성장하도록 노력하겠습니다.</p>
		</div>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,ref,computed } from 'vue'
	import useConfigStore from '@/store/modules/config'
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
</script>
<style lang="less">
  @import '@/assets/styles/common';
</style>
<style lang="less" scoped>
	.maxBox{flex-wrap: wrap;}
	h1{width: 100%;}
</style>
